.section-banner {
  background: #009688;
  background: linear-gradient(90deg, #9c27b0 0%, #311b92 100%);
  color: #fff;
}

.col-banner-left {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section-contact {
  text-align: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}

@media (max-width: 575px) {
  .col-banner-left {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) {
}
