.section-hero {
  background-color: #fff;
}

.app-icon {
  width: 4rem;
  height: 4rem;
}

.device {
  max-width: 280px;
}

.btn-download {
  width: 160px;
}
