@font-face {
  font-family: "Metropolis";
  font-weight: normal;
  src: url("/fonts/Metropolis-Regular.eot");
  src: url("/fonts/Metropolis-Regular.eot") format("embedded-opentype"),
    url("/fonts/Metropolis-Regular.woff2") format("woff2"),
    url("/fonts/Metropolis-Regular.woff") format("woff"),
    url("/fonts/Metropolis-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: bold;
  src: url("/fonts/Metropolis-Bold.eot");
  src: url("/fonts/Metropolis-Bold.eot") format("embedded-opentype"),
    url("/fonts/Metropolis-RegBoldular.woff2") format("woff2"),
    url("/fonts/Metropolis-Bold.woff") format("woff"),
    url("/fonts/Metropolis-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}

.info-container p {
  font-size: 0.875rem;
}
